import { defineStore } from 'pinia'

export const useInternal = defineStore('internal', {
  state: () => ({
    menu: false,
    content: false,
    loading: false,
    id: null,
    info: null,
    online: false
  }),
  getters: {
    isOnline() {
      return this.online
    }
  },
  actions: {
    useLoading(x) {
      this.loading = x
    },
    useContent(x) {
      this.content = x
    },
    useMenu(x) {
      this.menu = x
    },
    useId(x) {
      this.id = x
    },
    useOnline(x) {
      this.online = x
    },
    clearInfo() {
      this.info = null
    },
    async getInfo() {
      const config = useRuntimeConfig().public
      // const toasts = useToasts()
      const { acf } = await $fetch(config.CMS + '/wp-json/wp/v2/pages/112', {
        params: {
          _fields: 'acf.obavestenje,acf.objavi',
          _minimal: true,
          acf_format: 'standard'
        }
      })

      const { obavestenje, objavi } = acf
      // console.log('objavi ', objavi)
      if (obavestenje && objavi) {
        this.info = obavestenje
        // if (!toasts.toasts.some(x => x.id === 'obavestenje')) {
        //   toasts.addToast({
        //     id: 'obavestenje',
        //     content: obavestenje
        //   })
        // }
      }
    }
  }
})
