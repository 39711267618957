import { defineStore } from 'pinia'

export const useToasts = defineStore('toasts', {
  state: () => ({
    toasts: [
      // {
      //   id: 1,
      //   type: 'promotion',
      //   content: 'Moguće je kupiti online okove za nameštaj! Širok izbor okova je dostupan na dohvat ruke. <a href="/okov-za-namestaj">OKOVI</a>'
      // }
    ]
  }),
  actions: {
    removeToast(id) {
      this.toasts = this.toasts.filter(x => x.id !== id)
    },
    addToast(x) {
      this.toasts.push(x)
    }
  }
})
