<script setup>
const config = useRuntimeConfig()
const route = useRoute()
const info = useCookie('info', {
  watch: true
})

const cartStore = useCartStore()
const internalStore = useInternal()
const toastsStore = useToasts()
const isStandalone = ref(false)
const maxPullDistance = 50
const pullDistance = ref(0)
const isReloading = ref(false)
const touchStartY = ref(0)

useSeoMeta({
  twitterCard: 'summary_large_image',
  ogSiteName: 'Stilles',
  ogType: 'website',
  ogImage: config.public.APP_CARD,
  ogUrl: config.public.APP + route.fullPath
})

const onTouchStart = (e) => {
  // console.log('onTouchStart')
  if (!isStandalone.value) return
  touchStartY.value = e.touches[0].clientY
}

const onTouchMove = (e) => {
  // console.log('onTouchMove')
  if (!isStandalone.value || isReloading.value) return

  const touchY = e.touches[0].clientY
  const pull = touchY - touchStartY.value

  if (pull > 0 && window.scrollY === 0) {
    e.preventDefault()
    pullDistance.value = Math.min(pull, maxPullDistance)
  }
}

const onTouchEnd = () => {
  // console.log('onTouchEnd')
  if (!isStandalone.value) return

  if (pullDistance.value >= maxPullDistance) {
    isReloading.value = true
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
  else {
    pullDistance.value = 0
  }
}

const updateOnlineStatus = () => {
  const status = !navigator.onLine
  internalStore.useOnline(status)

  const id = 'onLine'
  if (!status) {
    // console.log('online')
    if (toastsStore.toasts.some(x => x.id === id)) {
      toastsStore.removeToast(id)
    }
  }
  else {
    // console.log('offline')
    toastsStore.addToast({
      id,
      content: `Nema internet veze...`
    })
  }
}

onMounted(async () => {
  // Check if the app is running in standalone mode
  isStandalone.value = window.matchMedia('(display-mode: standalone)').matches
  || window.navigator.standalone
  updateOnlineStatus()
  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
  await cartStore.callCart()
  if (typeof info.value === 'undefined' && internalStore.info === null) {
    await timeout(5000)
    await internalStore.getInfo()
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('online', updateOnlineStatus)
  window.removeEventListener('offline', updateOnlineStatus)
})
</script>

<template>
  <NuxtLayout>
    <!-- {{  internalStore.online ? 'TRUE' : 'FALSE'  }} -->
    <NuxtPage
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
    />
  </NuxtLayout>
</template>

<style>
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* remove default dot (•) sign */
::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element */
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
}

html {
  block-size: 100%;
  -webkit-tap-highlight-color: transparent;

  -webkit-tap-highlight-color: transparent;
}

body {
  position: relative;
  min-block-size: 100%;
  color: var(--color);
  background-color: var(--background-color);
}

main {
  padding: 24px 24px;
  position: relative;
}

.l {
  position: absolute;
  top: 212px;
  z-index: 999;
  height: 1px;
  width: 100%;
  background-color: magenta;
}

pre {
  color: magenta;
  font-size: 12px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.wrapper {
  max-width: 1440px;
  margin: 32px auto 64px;
}
::selection {
  background: #8884;
}
@media (max-width: 512px) {
  /* main {
    padding-top: 0;
  } */
  .wrapper {
  margin: 0 auto 64px;
}
}
</style>
