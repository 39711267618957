import { defineStore } from 'pinia'

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: null,
    loading: true,
    miniCart: false,
    key: null
  }),

  getters: {
    count() {
      const x = this.cart?.items?.length || 999
      if (import.meta.client && 'setAppBadge' in navigator) {
        try {
          if (x !== 0) {
            navigator.setAppBadge(x)
          }
          else navigator.clearAppBadge()
        }
        catch (error) {
          console.error(`count badge: ${error}`)
        }
      }
      return x
    },
    quantitySum() {
      return this.cart?.items?.map(x => x.quantity).reduce((partialSum, a) => partialSum + a, 0)
    }
  },

  actions: {
    useKey(x) {
      this.key = x
    },
    useLoading(x) {
      this.loading = x
    },
    useMiniCart(x) {
      this.miniCart = x
    },
    updateCart(x) {
      this.cart = x
    },
    removeCartToken() {
      this.cartToken = null
    },
    async callCart() {
      const config = useRuntimeConfig()
      const ct = useCookie('ct')
      const me = useCookie('me')
      try {
        this.loading = true
        const headers = {}
        // if (me.value?.user_id) {
        //   // headers['user-id'] = me.value?.user_id
        //   headers['authorization'] = 'Bearer ' + me.value?.token
        // }
        if (ct.value) headers['cart-token'] = ct.value

        const xx = await $fetch('/wp-json/wc/store/v1/cart?skip_cache=1', {
          baseURL: config.public.CMS,
          credentials: 'include',
          headers,
          pick: ['totals', 'billing_address', 'items', 'shipping_address', 'items_weight', 'cross_sells', 'shipping_rates'],
          onResponse(ctx) {
            for (const pair of ctx.response.headers.entries()) {
              console.log(pair[0] + ': ' + pair[1])
            }
            ct.value = ctx.response.headers.get('cart-token')
          }
        })
        console.log('ZZZZZZZZ ', ct.value)
        // console.log(JSON.stringify(data.value, null, 2))
        if (xx) {
          this.cart = xx
        }
        this.loading = false
      }
      catch (e) {
        console.error(e)
      }
    }
  }
})
