// https://king2088.github.io/vue-3d-loader-docs/
import vue3dLoader from 'vue-3d-loader'

export default defineNuxtPlugin({
  name: 'stilles-dwg',
  parallel: true,
  async setup(nuxtApp) {
    // the next plugin will be executed immediately
    nuxtApp.vueApp.use(vue3dLoader)
  }
})
