/* eslint-disable  */
import { useDark } from '@vueuse/core'
// Handling potential non-array values
// useFilter(this.plocasti, x => x.parent === 0)
export const useFilter = (arr, fn) => {
  return Array.isArray(arr)
    ? arr.filter(fn)
    : []
}

export function useMediaQuery(query) {
  const matches = ref(false)
  let mediaQuery

  onMounted(() => {
    mediaQuery = window.matchMedia(query)
    matches.value = mediaQuery.matches
    mediaQuery.addListener(update)
  })

  onUnmounted(() => mediaQuery.removeListener(update))

  function update(e) {
    matches.value = e.matches
  }

  return matches
}

// The debounce function takes two arguments, the function to be debounced and the wait time in milliseconds. It returns a function and can be called later:
// debounce(function() { ... }, 300)
export function debounce(fn, wait) {
  let timer
  return function (...args) {
    if (timer) {
      clearTimeout(timer) // clear any pre-existing timer
    }
    const context = this // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args) // call the function if time expires
    }, wait)
  }
}

export function debounceOpts(fn, wait, options = {}) {
  let timer = null;
  const isLoading = ref(false);
  return function (...args) {
    return new Promise((resolve, reject) => {
      var _a;
      if (timer) {
        clearTimeout(timer);
      }
      const context = this;
      if (!isLoading.value) {
        isLoading.value = true;
        (_a = options.onLoadingStart) === null || _a === void 0 ? void 0 : _a.call(options);
      }
      timer = setTimeout(async () => {
        var _a;
        try {
          const result = await fn.apply(context, args);
          resolve(result);
        }
        catch (error) {
          reject(error);
        }
        finally {
          isLoading.value = false;
          (_a = options.onLoadingEnd) === null || _a === void 0 ? void 0 : _a.call(options);
        }
      }, wait);
    });
  };
}

export function throttle(fn, wait) {
  let throttled = false;
  return function (...args) {
    if (!throttled) {
      fn.apply(this, args);
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, wait);
    }
  };
}

export const formatList = new Intl.ListFormat('sr-Latn', {
  style: 'long',
  type: 'conjunction'
})

export const isDark = useDark()

export function toggleDark(event: MouseEvent) {
  // @ts-expect-error experimental API
  const isAppearanceTransition = document.startViewTransition
    && !window.matchMedia('(prefers-reduced-motion: reduce)').matches

  if (!isAppearanceTransition) {
    isDark.value = !isDark.value
    return
  }

  const x = event.clientX
  const y = event.clientY
  const endRadius = Math.hypot(
    Math.max(x, innerWidth - x),
    Math.max(y, innerHeight - y),
  )
  // @ts-expect-error: Transition API
  const transition = document.startViewTransition(async () => {
    isDark.value = !isDark.value
    await nextTick()
  })
  transition.ready
    .then(() => {
      const clipPath = [
        `circle(0px at ${x}px ${y}px)`,
        `circle(${endRadius}px at ${x}px ${y}px)`,
      ]
      document.documentElement.animate(
        {
          clipPath: isDark.value
            ? [...clipPath].reverse()
            : clipPath,
        },
        {
          duration: 512,
          easing: 'ease-out',
          pseudoElement: isDark.value
            ? '::view-transition-old(root)'
            : '::view-transition-new(root)',
        },
      )
    })
}

export const timeout = (ms) => new Promise(res => setTimeout(res, ms))

export const useTo = (x, sufix = '') => sufix + (x || '/')?.replace(/^.*\/\/[^\/]+/, '') 
